import React, { useState, useEffect } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import { postData, getData } from "../../FetchService";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as yup from "yup";
import { toast, ToastContainer } from "react-toastify";
import ErrorMessages from "../../CommonPages/ErrorMessages";

const AddEditHealthArticle = ({ imageSeter, image, convertImageToUrl }) => {
  let seter = imageSeter;
  const navigate = useNavigate();
  const [valImage, setValImage] = useState("");
  const [valTemplate, setValTemplate] = useState("");
  const [data, setData] = useState([]);
  const [tempId, setTempId] = useState([]);
  const [images, setImages] = useState([]);
  const [formValues, setFormValues] = useState({
    name: "",
    description: "",
    image: "",
    tempId: [],
  });
  const [blob, setBlob] = useState("");

  const handleFormValues = (e) => {
    const { name, value } = e.target;
    setFormValues({ ...formValues, [name]: value });
  };

  const handleTempId = (e) => {
    let idd = e.target.value;
    let a = data?.filter((item) => {
      return item._id == idd;
    });
    let fill = tempId.filter((el) => el._id == idd);
    if (!fill.length) {
      setTempId([...tempId, a[0]]);
    }
  };

  const handleGetMangeData = (id) => {
    let b = tempId;
    if (b) {
      b = tempId.filter((el) => {
        return el._id !== id;
      });
    }

    setTempId(b);
  };

  const apiHandler = async () => {
    let res = await getData("health-categories");

    setData(res.data);
  };
  useEffect(() => {
    apiHandler();
    return () => {
      setBlob("");
      seter("");
    };
  }, []);
  let a;
  const handleAddEvent = async (value) => {
    myValidation();
    a = tempId?.map((el) => {
      return el._id;
    });

    const data = {
      name: value.name,
      image: image,
      description: value.description,
      categories: a,
    };
    if (valImage || valTemplate) {
      const res = await postData("add-health-article", data);
      if (res.status === true) {
        seter("");
        setBlob("");
        setTimeout(() => {
          navigate("/managehealtharticles", { state: { data: data } });
        }, 1000);
        toast.success(" Added successfully  !", {
          position: toast.POSITION.TOP_RIGHT,
        });
      } else {
        toast.error(res.message, {
          position: toast.POSITION.TOP_RIGHT,
        });
      }
    } else {
      toast.error("Something went wrong !", {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  };

  const healthArticleValidation = yup.object().shape({
    name: yup.string().required("Name is required !"),
    description: yup.string().required("Description is required !"),
    // image: yup.string().required("Image is required !"),
    // tempId: yup.string().required("Minimum 1 template is required !"),
  });

  const myValidation = () => {
    if (!tempId.length) {
      setValTemplate("Minimum 1 template is required !");
    } else {
      setValTemplate("");
    }
    if (!blob) {
      setValImage("Image is required !");
    } else {
      setValImage("");
    }
  };

  return (
    <>
      <ToastContainer autoClose={1000} />
      <div className="main mt-8">
        <div className="content container-fluid addEditHealthArticles">
          <div className="page-header border-0 pb-0">
            <div className="row align-items-center">
              <div className="col-12 col-sm d-flex align-items-center">
                <div className="row align-items-center g-3 mb-2">
                  <div className="col">
                    <h1 className="page-header-title mb-0">
                      Add Health Article
                    </h1>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <Formik
            onSubmit={handleAddEvent}
            initialValues={formValues}
            validationSchema={healthArticleValidation}
          >
            {({ values, errors, touched }) => {
              return (
                <Form>
                  <div className="row g-3">
                    <div className="col-12">
                      <div className="row g-2 mb-4">
                        <div className="col-12 col-sm-6 col-md-5 col-lg-4">
                          <div className="card">
                            <div className="card-body p-0">
                              <label
                                htmlFor="attachFilesNewProjectLabel"
                                className="js-dropzone dz-dropzone dz-dropzone-card"
                              >
                                <div className="dz-message">
                                  <>
                                    <img
                                      className="avatar avatar-xl avatar-4x3 mb-3"
                                      src={blob}
                                      alt="Image Description"
                                      data-hs-theme-appearance="default"
                                    />
                                    <img
                                      className="avatar avatar-xl avatar-4x3 mb-3 d-none"
                                      src={blob}
                                      alt="Image Description"
                                      data-hs-theme-appearance="dark"
                                    />
                                  </>

                                  <h5>Drag and drop your file here</h5>
                                  <p className="mb-2">or</p>
                                  <span className="btn btn-white btn-sm">
                                    Browse files
                                  </span>
                                </div>
                              </label>
                              <Field
                                type="file"
                                className="d-none"
                                name="image"
                                id="attachFilesNewProjectLabel"
                                onChange={(e) => {
                                  convertImageToUrl(e);
                                  setBlob(
                                    URL.createObjectURL(e.target.files[0])
                                  );
                                  if (blob) {
                                    setValImage("");
                                  }
                                }}
                              />
                            </div>
                          </div>
                        </div>
                        <div className="col-12">
                          <div className="text-dark mt-2">
                            Upload Article Image
                          </div>
                        </div>
                        {valImage && (
                          <div className="text-danger mt-2">{valImage}</div>
                        )}
                      </div>
                    </div>
                    <div className="col-12">
                      <div className="row g-3">
                        <div className="col-12 col-lg-6">
                          <div className="col-12">
                            <div className="text-dark mt-2 mb-2">
                              Event template
                            </div>
                          </div>
                          <div className="tom-select-custom">
                            <select
                              className="js-select form-select"
                              autoComplete="off"
                              defaultValue="DEFAULT"
                              onChange={handleTempId}
                              data-hs-tom-select-options=""
                            >
                              <option
                                value="DEFAULT"
                                disabled
                                className="text-muted"
                              >
                                {" "}
                                Select Health Category (Multiple Selection)
                              </option>
                              {data?.map((el) => {
                                return (
                                  <option
                                    value={el._id}
                                    key={el._id}
                                    id={el._id}
                                  >
                                    {el.name}
                                  </option>
                                );
                              })}
                            </select>
                            {valTemplate && (
                              <div className="text-danger mt-2">
                                {valTemplate}
                              </div>
                            )}
                          </div>
                        </div>
                        <div className="col-12 col-lg-6">
                          <div className="row g-3">
                            {tempId?.map((item) => {
                              return (
                                <div key={Math.random()} className="col-auto">
                                  <div className="bg-white border rounded-pill py-2 px-5 position-relative">
                                    <span>{item.name}</span>
                                    <button
                                      type="button"
                                      onClick={() =>
                                        handleGetMangeData(item._id)
                                      }
                                      className="btn p-0 border-0 position-absolute closeBtn"
                                      aria-label="Close"
                                    >
                                      <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        aria-hidden="true"
                                        className="svg-icon fa-svg-icon svg-fa-cancel"
                                        width="25"
                                        height="25"
                                        style={{
                                          display: "flex",
                                          position: "relative",
                                          top: "-15",
                                          left: "10",
                                        }}
                                        viewBox="0 0 24 24"
                                      >
                                        <path d="M12 2C6.47 2 2 6.47 2 12s4.47 10 10 10 10-4.47 10-10S17.53 2 12 2zm5 13.59L15.59 17 12 13.41 8.41 17 7 15.59 10.59 12 7 8.41 8.41 7 12 10.59 15.59 7 17 8.41 13.41 12 17 15.59z"></path>
                                      </svg>
                                    </button>
                                  </div>
                                </div>
                              );
                            })}
                          </div>
                        </div>
                        <div className="col-12 col-lg-6">
                          <div className="col-12">
                            <div className="text-dark mt-2 mb-2">
                              Event name
                            </div>
                          </div>
                          <Field
                            type="text"
                            className="form-control"
                            name="name"
                            placeholder="Enter Article Name"
                          />
                          {errors.name && touched.name ? (
                            <ErrorMessage
                              name="name"
                              component={ErrorMessages}
                            />
                          ) : null}
                        </div>
                        <div className="col-12">
                          <div className="col-12">
                            <div className="text-dark mt-2 mb-2">
                              Event description
                            </div>
                          </div>
                          <Field
                            as="textarea"
                            className="form-control"
                            name="description"
                            placeholder="Enter Article Description"
                          ></Field>
                          {errors.description && touched.description ? (
                            <ErrorMessage
                              name="description"
                              component={ErrorMessages}
                            />
                          ) : null}
                        </div>
                        <div className="col-12">
                          <button className="btn btn-dark px-5" type="submit">
                            ADD
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </Form>
              );
            }}
          </Formik>
        </div>
      </div>
    </>
  );
};

export default AddEditHealthArticle;
