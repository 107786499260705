import React, { useState, useEffect, useCallback } from "react";
import { NavLink, Navigate, useNavigate } from "react-router-dom";
import { getData, putData } from "../../FetchService";
import DataTable from "react-data-table-component";
import { ToastContainer, toast } from "react-toastify";

const ManageEventTemplate = () => {
  const [allevents, setAllEvents] = useState([]);
  const [activityItem, setActivityItem] = useState("");
  const [filterdata, setFilterData] = useState([]);
  const [search, setsearch] = useState("");
  const [row, setRow] = useState([]);
  const [toggleRow, setToggleRow] = useState(false);
  const [multipleRow, setMultipleRow] = useState();

  const navigate = useNavigate();

  const handleEvent = async () => {
    const res = await getData("event-templates");
    setAllEvents(res.data);
  };
  const handleActivity = async () => {
    const res = await getData("activity-templates");
    setActivityItem(res.data);
  };

  useEffect(() => {
    handleEvent();
    handleActivity();
  }, []);

  const deleteHandler = async (id) => {
    setToggleRow(!toggleRow);
    const body = { id: [id] };
    const res = await putData("delete-templates", body);
    if (res.status == true) {
      handleEvent();
      return toast.success(`${res.message} Successfully !`, {
        position: toast.POSITION.TOP_RIGHT,
      });
    } else {
      handleEvent();
      toast.error(`${res.message} !`, {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  };
  const searchFilter = (e) => {
    e.preventDefault();

    setsearch(e.target.value);
    const filterData = allevents?.filter((item) => {
      if (item.name.toLowerCase().includes(e.target.value)) {
        return item;
      }
    });
    setFilterData(filterData);
  };

  const columns = [
    {
      name: "SR.NO",
      selector: (row, i) => i + 1,
    },
    {
      name: "EVENT TITLE",
      selector: (row) =>
        row.name ? row.name : <span className="text-danger">Not Given</span>,
      sortable: true,
    },
    {
      name: "	ACTIVITY TYPE",
      // selector: (row) => (row.activities ? row.activities : "No Activities"),
      selector: (row) =>
        activityItem ? (
          activityItem
            .filter((item) => row.activities?.includes(item.ref))
            .map((item) =>
              item.activity.length == 1 ? item.activity : item.activity + ","
            )
        ) : (
          <span className="text-danger">Not Given</span>
        ),
      sortable: true,
    },
    {
      name: "DISTANCE ( KMs )",
      selector: (row) =>
        row.distance.daily ? (
          (row.distance.daily / 1000).toFixed(1) + " KMs "
        ) : (
          <span className="text-danger">Not Given</span>
        ),
      sortable: true,
    },
    {
      name: "CALORIES",
      selector: (row) =>
        row.calories.daily ? (
          row.calories.daily
        ) : (
          <span className="text-danger">Not Given</span>
        ),
      sortable: true,
    },
    {
      name: "ACTIVITY TIME ( mins )",
      selector: (row) =>
        row.time.daily ? (
          (row.time.daily / 60).toFixed(1) + " mins"
        ) : (
          <span className="text-danger">Not Given</span>
        ),
      sortable: true,
    },
    {
      name: "Actions",
      center: true,
      cell: (row) => (
        <div className="d-flex justify-content-center">
          <NavLink
            className="btn btn-white btn-sm border-0"
            to="allactivities"
            state={{ isEdit: true, data: row }}
          >
            <i className="bi-pencil-fill me-1"></i>
          </NavLink>

          <NavLink
            className="btn btn-white btn-sm border-0"
            to=""
            data-bs-toggle="modal"
            data-bs-target="#askdeletecategory"
            onClick={() => {
              setRow(row._id);
            }}
          >
            <i
              className="bi bi-trash-fill"
              onClick={() => {
                setRow(row._id);
              }}
            ></i>
          </NavLink>
        </div>
      ),
    },
  ];

  const handleSelectedRowsChange = useCallback(
    (row) => {
      const selected = row.selectedRows.map((x) => x._id);
      setMultipleRow(selected);
    },
    [multipleRow]
  );

  const handleMultipleDelete = async () => {
    let body = {
      id: multipleRow,
    };

    const res = await putData("delete-templates", body);
    if (res.status == true && multipleRow.length > 0) {
      handleEvent();
      return toast.success(
        `${multipleRow.length} health category deleted Successfully !`,
        {
          position: toast.POSITION.TOP_RIGHT,
        }
      );
    } else if (res.status == false) {
      handleEvent();
      toast.error(`${res.message} !`, {
        position: toast.POSITION.TOP_RIGHT,
      });
    } else {
      toast.error(`No health category select!`, {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
    setToggleRow(!toggleRow);
    setMultipleRow([]);
  };

  return (
    <div className="main mt-8">
      <div className="content container-fluid">
        <div className="pageheader">
          <div className="row align-items-center mb-3">
            <div className="col-sm mb-2 mb-sm-0">
              <h1 className="page-header-title m-0">Manage Events</h1>
            </div>
            <div className="col px-0 active_inactive_btn_bar d-flex gap-2 justify-content-end">
              <NavLink
                className="btn btn-dark addEventBtn"
                to="allactivities"
                state={{ isEdit: false }}
              >
                Add Event Template
              </NavLink>
            </div>
          </div>
        </div>
        <div className="row justify-content-end mb-3">
          <div className="col-lg">
            <div id="datatableCounterInfo" style={{ display: "none" }}>
              <div className="d-sm-flex justify-content-lg-end align-items-sm-center">
                <span className="d-block d-sm-inline-block fs-5 me-3 mb-2 mb-sm-0">
                  <span id="datatableCounter">0</span>
                  Selected
                </span>
                <div className="btn btn-outline-danger btn-sm mb-2 mb-sm-0 me-2">
                  <i className="bi-trash"></i> Delete
                </div>
                <div className="btn btn-white btn-sm mb-2 mb-sm-0 me-2">
                  <i className="bi-archive"></i> Archive
                </div>
                <div className="btn btn-white btn-sm mb-2 mb-sm-0 me-2">
                  <i className="bi-upload"></i> Publish
                </div>
                <div className="btn btn-white btn-sm mb-2 mb-sm-0">
                  <i className="bi-x-lg"></i> Unpublish
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="card">
          <div className="card-header border-bottom-0 card-header-content-md-between row">
            <div className="mb-2 mb-md-0 col-auto">
              <form>
                <div className="input-group input-group-merge input-group-flush">
                  <div className="input-group-prepend input-group-text">
                    <i className="bi-search"></i>
                  </div>
                  <input
                    id="datatableSearch"
                    type="search"
                    className="form-control"
                    placeholder="Search users"
                    aria-label="Search users"
                    onChange={(e) => searchFilter(e)}
                    onKeyPress={(event) => {
                      if (event.charCode == 13) {
                        // // setsearch(event)
                        searchFilter(event);
                      }
                    }}
                  />
                </div>
              </form>
            </div>
          </div>
          <div className="card-header card-header-content-between">
            <div className="mb-2 mb-md-0 me-2">
              <button
                type="button"
                className="btn btn-white btn-sm border"
                data-bs-toggle="modal"
                data-bs-target="#multypleEventDelete"
              >
                <i className="bi bi-trash-fill fs-1"></i>
              </button>
            </div>
          </div>
          <div className="table-responsive datatable-custom">
            <DataTable
              pagination
              columns={columns}
              data={search ? filterdata : allevents}
              selectableRows
              onSelectedRowsChange={handleSelectedRowsChange}
              clearSelectedRows={toggleRow}
              fixedHeader
              fixedHeaderScrollHeight="408px"
              selectableRowsHighlight
              highlightOnHover
            />
          </div>
        </div>
        <ToastContainer autoClose={1000} />

        {/* Modal for Delete */}
        <div
          className="modal fade"
          id="askdeletecategory"
          tabIndex="-1"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog modal-dialog-centered" role="document">
            <div className="modal-content">
              <div className="modal-header">
                <h5
                  className="modal-title text-danger"
                  id="exampleModalLongTitle"
                >
                  Alert !
                </h5>
              </div>
              <div className="modal-body">
                Do you want to delete the Event -{" "}
                {/* <strong>{row.categories}</strong> */}
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-danger"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                  onClick={() => deleteHandler(row)}
                >
                  Delete
                </button>
                <button
                  type="button"
                  className="btn btn-secondary"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                >
                  Close
                </button>
              </div>
            </div>
          </div>
        </div>
        <div
          className="modal fade "
          id="multypleEventDelete"
          tabIndex="-1"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog modal-dialog-centered" role="document">
            <div className="modal-content">
              <div className="modal-header">
                <h5
                  className="modal-title text-danger"
                  id="exampleModalLongTitle"
                >
                  Alert !
                </h5>
              </div>
              <div className="modal-body">
                Do you want to delete {multipleRow?.length} events
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-danger"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                  onClick={() => handleMultipleDelete()}
                >
                  Delete
                </button>
                <button
                  type="button"
                  className="btn btn-secondary"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                >
                  Close
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ManageEventTemplate;
