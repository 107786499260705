import React, { useCallback, useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import { NavLink } from "react-router-dom";
import Header from "../../CommonPages/Header";
import SideBar from "../../CommonPages/SideBar";
import { getData, putData } from "../../FetchService";
import { ColorRing } from "react-loader-spinner";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Moment from "react-moment";
const ManagePublicEvent = () => {
  const [allEvents, setAllEvents] = useState([]);
  const [toggleRow, setToggleRow] = useState(false);
  const [filterdData, setFilterdData] = useState([]);
  const [multipleRow, setMultipleRow] = useState();
  const [search, setsearch] = useState("");
  const [opt, setopt] = useState("");
  const [eventStatus, setEventStatus] = useState("");
  const [loader, setLoader] = useState(true);

  const handleAllEvent = async () => {
    setLoader(true);
    const res = await getData("events");
    let a = res.data.filter((el) => {
      if (el.isCorporate == false) {
        return el;
      }
    });
    setAllEvents(a);
    setFilterdData(a);
    setLoader(false);
  };
  useEffect(() => {
    handleAllEvent();
  }, []);

  useEffect(() => {
    if (search) {
      const filterData = allEvents?.filter((item) =>
        item.name.toLowerCase().includes(search.trim().toLowerCase())
      );
      setFilterdData(filterData);
    } else {
      setFilterdData(allEvents);
    }
  }, [search]);

  const searchFilter = (e) => {
    e.preventDefault();
    setsearch(e.target.value);
  };
  const optionFilter = (e) => {
    setopt(e.target.value);
  };

  const eventStatusHandle = (e) => {
    setEventStatus(e.target.value);
  };

  const eventStatusFilter = (stat) => {
    if (stat.event == "upcoming" && stat.am == "free") {
      let a = filterdData.filter((el) => el.status == 1 && el.amount == 0);
      setFilterdData(a);
    } else if (stat.event == "ongoing" && stat.am == "free") {
      let b = filterdData.filter((el) => el.status == 2 && el.amount == 0);
      setFilterdData(b);
    } else if (stat.event == "completed" && stat.am == "free") {
      let c = filterdData.filter((el) => el.status == 3 && el.amount == 0);
      setFilterdData(c);
    } else if (stat.event == "all") {
      let c = filterdData.filter((el) => el.status);
      setFilterdData(c);
    } else if (stat.event == "upcoming" && stat.am == "paid") {
      let d = filterdData.filter((el) => el.status == 1 && el.amount > 0);
      setFilterdData(d);
    } else if (stat.event == "ongoing" && stat.am == "paid") {
      let e = filterdData.filter((el) => el.status == 2 && el.amount > 0);
      setFilterdData(e);
    } else if (stat.event == "completed" && stat.am == "paid") {
      let f = filterdData.filter((el) => el.status == 3 && el.amount > 0);
      setFilterdData(f);
    } else {
      return false;
    }
  };
  const handleChange = useCallback(
    (row) => {
      const handle = row.selectedRows.map((x) => {
        return x._id;
      });
      setMultipleRow(handle);
    },
    [multipleRow]
  );

  const handleMultipleDelete = async () => {
    let data = {
      id: multipleRow,
    };

    const res = await putData("delete-events", data);
    if (res.status == true && multipleRow.length > 0) {
      handleAllEvent();
      return toast.success(
        `${multipleRow.length} health category deleted Successfully !`,
        {
          position: toast.POSITION.TOP_RIGHT,
        }
      );
    } else if (res.status == false) {
      handleAllEvent();
      toast.error(`${res.message} !`, {
        position: toast.POSITION.TOP_RIGHT,
      });
    } else {
      toast.error(`No health category select!`, {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
    setToggleRow(!toggleRow);
    setMultipleRow([]);
  };
  const handleClearFilter = () => {
    setEventStatus("");
    setopt("");
    if (search.includes(" ")) {
      setsearch(search.trim());
    } else {
      setsearch(search + " ");
    }
  };

  const columns = [
    {
      name: "SR.NO",
      cell: (row, index) => index + 1,
      center: true,
      style: {
        textAlign: "center",
      },
    },
    {
      name: "Event Title",
      selector: (row) => (row.name ? row.name : "name"),
      sortable: true,
      //   center: true,
      style: {
        textAlign: "center",
      },
    },
    {
      name: "Event Type",
      selector: (row) => (row.amount == 0 ? "FREE" : "PAID"),
      sortable: true,
      center: true,
      style: {
        textAlign: "center",
      },
    },
    {
      name: "Event Amount ",
      selector: (row) => row.amount,
      sortable: true,
      center: true,
      style: {
        textAlign: "center",
      },
    },
    {
      name: "Start Date",
      selector: (row) => (
       `${new Date(row.startDate).toLocaleString()}`
      ),
      sortable: true,
      center: true,
      style: {
        width: "480px",
        textAlign: "center",
      },
    },
    {
      name: "End Date",
      selector: (row) => (
      `${new Date(row.endDate).toLocaleString()}`
      ),
      sortable: true,
      center: true,
      style: {
        width: "480px",
        textAlign: "center",
      },
    },
    {
      name: "Qualifying Activities",
      cell: (row) =>
        row.activities?.map((el) =>
          el == 1
            ? "Running "
            : "" || el == 2
            ? " Cycling "
            : "" || el == 3
            ? " Jumping "
            : "" || el == 4
            ? " Walking "
            : "" || el == 5
            ? "Skipping "
            : "" || (el == 1 && el == 2 && el == 3 && el == 4 && el == 5)
            ? "All"
            : ""
        ),

      sortable: true,
      center: true,
      style: {
        textAlign: "center",
      },
    },
    {
      name: "No. of Participants",
      selector: (row) => row?.participants,
      sortable: true,
      center: true,
      style: {
        textAlign: "center",
      },
    },
    // {
    //   name: "Event Admin",
    //   selector: (row) =>
    //     row?.admin
    //       ? `${row?.admin?.firstName} ${row?.admin?.lastName}`
    //       : "no admin",
    //   sortable: true,
    //   center: true,
    //   style: {
    //     textAlign: "center",
    //   },
    // },
    {
      name: "Event Status",
      selector: (row) =>
        row.status == 1 ? (
          <span className="text-primary">Upcoming</span>
        ) : row.status == 2 ? (
          <span className="text-warning">Ongoing</span>
        ) : row.status == 3 ? (
          <span className="text-success">Completed</span>
        ) : (
          ""
        ),
      sortable: true,
      center: true,
      style: {
        textAlign: "center",
      },
    },
    {
      name: "Actions",
      cell: (row) => (
        <div className="d-flex justify-content-center">
          {row.amount == 0 ? (
            <NavLink
              className="btn btn-white btn-sm border-0"
              to="vieweventdetailsfree"
              state={{ items: row._id, user: row }}
            >
              <i className="bi bi-eye-fill me-1"></i>
            </NavLink>
          ) : (
            <NavLink
              className="btn btn-white btn-sm border-0"
              to="vieweventdetailspaid"
              state={{ items: row._id, user: row }}
            >
              <i className="bi bi-eye-fill me-1"></i>
            </NavLink>
          )}
        </div>
      ),
      center: true,
      style: {
        textAlign: "center",
      },
    },
  ];
  return (
    <div>
      <div className="main mt-8">
        <div className="content container-fluid manage_user manage_event_list managePublicEvent">
          <div className="pageheader">
            <div className="row align-items-center mb-3">
              <div className="col">
                <h1 className="page-header-title m-0">Manage Public Events</h1>
              </div>
              <div className="col-auto">
                <NavLink
                  className="btn btn-dark addEventBtn"
                  to="managepublicaddevent"
                  state={{ isEdit: false }}
                >
                  Add Event
                </NavLink>
              </div>
            </div>
          </div>
          <div className="card">
            <div className="card-header border-bottom-0 card-header-content-md-between row gap-3 gap-md-5">
              <div className=" col-md-auto">
                <form>
                  <div className="input-group input-group-merge input-group-flush">
                    <div className="input-group-prepend input-group-text">
                      <i className="bi-search"></i>
                    </div>
                    <input
                      value={search}
                      type="text"
                      onChange={(e) => searchFilter(e)}
                      onKeyPress={(event) => {
                        if (event.charCode == 13) {
                          searchFilter(event);
                          setsearch(search + " ");
                        }
                      }}
                      className="form-control py-md-2"
                      placeholder="Search events"
                    />
                    {search ? (
                      <i
                        onClick={() => {
                          setsearch("");
                        }}
                        className="bi bi-x fs-2"
                      ></i>
                    ) : null}
                  </div>
                </form>
              </div>

              <div className="col-md active_inactive_btn_bar ps-md-0 justify-content-md-end">
                <div>
                  <div className="row align-items-center gap-2 gap-sm-0 justify-content-md-end">
                    <div className="col-sm-auto pe-md-0">
                      <div className="text-black fw-bold filterTitle">
                        Filter By
                      </div>
                    </div>
                    <div className="col-sm-auto col-12 pe-md-0">
                      <div className="row">
                        <div className="col-sm-auto col-6 pe-md-0">
                          <div className="customSelect">
                            <select
                              className="form-select"
                              aria-label="Default select example"
                              defaultValue={"Select Event Type"}
                              onChange={(e) => optionFilter(e)}
                              value={opt}
                            >
                              <option value={"Select Event Type"}>
                                Select Event Type
                              </option>
                              <option value={"free"}>FREE</option>
                              <option value={"paid"}>PAID</option>
                            </select>
                          </div>
                        </div>
                        <div className="col-sm-auto col-6">
                          <div className="customSelect">
                            <select
                              className="form-select"
                              aria-label="Default select example"
                              defaultValue={"Select Status"}
                              onChange={(e) => eventStatusHandle(e)}
                              value={eventStatus}
                            >
                              <option value={"Select Status"}>
                                Select Status
                              </option>
                              {/* <option value="1">All</option> */}
                              <option value="upcoming">Upcoming</option>
                              <option value="ongoing">Ongoing</option>
                              <option value="completed">Completed</option>
                            </select>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-sm-auto filter_apply_rowBtn gap-2 d-flex pe-md-0">
                      <button
                        className="btn btn-dark"
                        type="button"
                        onClick={() =>
                          eventStatusFilter({ event: eventStatus, am: opt })
                        }
                      >
                        Apply
                      </button>
                      <button
                        className="btn btn-dark"
                        onClick={() => handleClearFilter()}
                        type="button"
                      >
                        Clear
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <div className="deleteBtn">
                <button
                  type="button"
                  className="btn btn-white border-0"
                  data-bs-toggle="modal"
                  data-bs-target="#multypleEventDelete"
                >
                  <i className="bi bi-trash-fill fs-1"></i>
                </button>
              </div>
            </div>
            {loader ? (
              <div style={{ marginLeft: "400px" }}>
                <ColorRing
                  visible={true}
                  height="80"
                  width="80"
                  ariaLabel="blocks-loading"
                  wrapperStyle={{ marginTop: "100px" }}
                  wrapperClass="blocks-wrapper"
                  colors={[
                    "#b8c480",
                    "#B2A3B5",
                    "#F4442E",
                    "#51E5FF",
                    "#429EA6",
                  ]}
                />
              </div>
            ) : (
              <div className="table-responsive datatable-custom ">
                <DataTable
                  columns={columns}
                  data={filterdData}
                  pagination
                  selectableRows
                  onSelectedRowsChange={handleChange}
                  clearSelectedRows={toggleRow}
                />
              </div>
            )}
            <ToastContainer autoClose={1000} />
          </div>
          {/* modal multiple delete  */}
          <div
            className="modal fade "
            id="multypleEventDelete"
            tabIndex="-1"
            aria-labelledby="exampleModalLabel"
            aria-hidden="true"
          >
            <div className="modal-dialog modal-dialog-centered" role="document">
              <div className="modal-content">
                <div className="modal-header">
                  <h5
                    className="modal-title text-danger"
                    id="exampleModalLongTitle"
                  >
                    Alert !
                  </h5>
                </div>
                <div className="modal-body">
                  Do you want to delete {multipleRow?.length} events
                </div>
                <div className="modal-footer">
                  <button
                    type="button"
                    className="btn btn-danger"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                    onClick={() => handleMultipleDelete()}
                  >
                    Delete
                  </button>
                  <button
                    type="button"
                    className="btn btn-secondary"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                  >
                    Close
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ManagePublicEvent;
