import React from "react";
import Header from "../../CommonPages/Header";
import SideBar from "../../CommonPages/SideBar";
import { useState, useRef, useMemo } from "react";
import JoditEditor from "jodit-react";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import { postData } from "../../FetchService";

function TextEditorCms() {
  const location = useLocation();

  const editor = useRef(null);
  const [aboutUs, setAboutUs] = useState("");
  const [privacyPolicy, setPrivacyPolicy] = useState("");
  const [termsAndConditions, setTermsAndConditions] = useState("");
  const [data, setdata] = useState(
    location.state.data ? location.state.data : ""
  );
  const navigate = useNavigate();
  console.log(location.state);
  const handleCMs = async () => {
    let state = location.state.cms;

    let body;

    if (state === "aboutUs") {
      body = {
        aboutUs: data,
      };
    } else if (state === "privacyPolicy") {
      body = {
        privacyPolicy: data,
      };
    } else if (state === "termsAndConditions") {
      body = { termsAndConditions: data };
    }

    const res = await postData("change-cms", body);
  };

  const config = {
    readonly: location?.state?.type == "view",
  };

  return (
    <>
      <div className="main mt-8">
        <div className="content container-fluid manage_user manage_event_list managePublicEvent">
          <div className="pageheader">
            <div className="row g-2 align-items-center mb-3">
              <div className="d-flex flex-row align-items-center">
                <h1 className="page-header-title m-0"> Edit CMS </h1>{" "}
                <div className="ms-2">
                  {location?.state.cms == "aboutUs"
                    ? "(About Us)"
                    : location?.state.cms == "privacyPolicy"
                    ? "(Privacy Policy)"
                    : location?.state.cms == "termsAndConditions"
                    ? "(Terms & Conditions)"
                    : ""}
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-auto">
              <form action="">
                <div className="customTextArea">
                  <div>
                    <JoditEditor
                      // ref={editor}
                      // readonly = {location?.state?.type == 'view'}
                      value={data}
                      config={config}
                      tabIndex={1}
                      // onBlur={
                      //   location.state.cms == "aboutUs"
                      //     ? setAboutUs
                      //     : location.state.cms == "privacyPolicy"
                      //     ? setPrivacyPolicy
                      //     : location.state.cms == "termsAndConditions"
                      //     ? setTermsAndConditions
                      //     : ""
                      // }
                      onChange={(e) => setdata(e)}
                    />
                  </div>
                </div>
                {location?.state?.type != "view" ? (
                  <div className=" d-flex align-items-center gap-3 mt-4">
                    <NavLink to="/managecms">
                      <button
                        type="button"
                        className="btn btn-dark"
                        onClick={() => handleCMs()}
                      >
                        Submit
                      </button>
                    </NavLink>
                    <button
                      type="button"
                      className="btn btn-dark"
                      onClick={() => navigate("/managecms")}
                    >
                      Cancel
                    </button>
                  </div>
                ) : (
                  ""
                )}
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default TextEditorCms;
