import React from "react";
import { useState } from "react";
import Header from "../../CommonPages/Header";
import SideBar from "../../CommonPages/SideBar";
import { postData } from "../../FetchService";
import { useLocation, useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import ErrorMessages from "../../CommonPages/ErrorMessages";

const AddNewPlan = () => {
  let navigate = useNavigate();
  const [formValue, setFormValue] = useState({
    name: "",
    description: "",
    price: "",
    points: "",
    month: "",
    events: "",
  });

  const submitData = async (value) => {
    const data = {
      name: value?.name,
      description: value?.description,
      price: Math.floor(value?.price),
      monthDuration: Math.floor(value?.month),
      eventsFree: Math.floor(value?.events),
      pointsMultiplier: parseFloat(value?.points),
    };
    const res = await postData("add-subscription-plan", data);
    setFormValue({
      name: "",
      description: "",
      price: "",
      points: "",
      month: "",
      events: "",
    });
    if (res.status == true) {
      setTimeout(() => {
        navigate("/managesubscriptionplans");
      }, 2000);
      toast.success(`${res.message} !`, {
        position: toast.POSITION.TOP_RIGHT,
      });
    } else {
      toast.error(`${res.message} !`, {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  };
  const formSchema = Yup.object().shape({
    name: Yup.string().required("Name is required !"),
    description: Yup.string().required("Description is required!"),
    price: Yup.string().required("Price is required!"),
    points: Yup.string().required("Point is required!"),
    month: Yup.string().required("Month is required!"),
    events: Yup.string().required("Event is required!"),
  });
  return (
    <>
      <ToastContainer autoClose={1000} />
      <div className="main mt-8">
        <div className="content container-fluid">
          <div className="pageheader">
            <div className="row align-items-center mb-3">
              <div className="col-sm mb-2 mb-sm-0">
                <h1 className="page-header-title m-0">Add New Plan</h1>
              </div>
            </div>
          </div>
          <div className="card card-lg mb-3 mb-lg-5">
            {/* <div className="card-header px-sm-4 px-3">
              <h4 className="card-header-title">Details</h4>
            </div> */}
            <Formik
              initialValues={formValue}
              onSubmit={submitData}
              validationSchema={formSchema}
            >
              {({ errors, touched }) => {
                return (
                  <Form className="card-body p-3 p-sm-4">
                    <div className=" col-sm-4 mb-3">
                      <label htmlFor="eventtitle" className="form-label">
                        <b>Plan Title </b>
                      </label>
                      <Field
                        type="text"
                        className="form-control  "
                        placeholder="Enter Event Title"
                        name="name"
                      />
                      {errors.name && touched.name ? (
                        <ErrorMessage name="name" component={ErrorMessages} />
                      ) : null}
                    </div>
                    <div className="mb-3">
                      <label htmlFor="messageText" className="form-label">
                        <b>Description</b>
                      </label>
                      <Field
                        as="textarea"
                        name="description"
                        className="form-control "
                      />
                      {errors.name && touched.name ? (
                        <ErrorMessage
                          name="description"
                          component={ErrorMessages}
                        />
                      ) : null}
                    </div>

                    <div className="col-lg-3 col-md-4 col-sm-6 col-12 ps-sm-2 px-0 form-check">
                      <div className="fw-bold fs-4 text-black mb-3"> Price</div>
                      <div className="mb-3">
                        <Field
                          type="number"
                          className="form-control "
                          placeholder="Enter Price"
                          name="price"
                        />
                        {errors.name && touched.name ? (
                          <ErrorMessage
                            name="price"
                            component={ErrorMessages}
                          />
                        ) : null}
                      </div>
                    </div>
                    <div className="row mx-0 ">
                      <div className="col-12 chech_Box_Custom px-0">
                        <div className="tab-content" id="myTabContent">
                          <div
                            className="tab-pane fade active show"
                            id="home"
                            role="tabpanel"
                            aria-labelledby="home-tab"
                          >
                            <div className="row mx-0">
                              <div className="col-lg-3 col-md-4 col-sm-6 col-12 form-check px-0 pe-sm-2">
                                <div className="fw-bold fs-4 text-black mb-3 ">
                                  Multiple Points
                                </div>
                                <div className="mb-3">
                                  <Field
                                    type="number"
                                    className="form-control "
                                    id="enterpoints"
                                    placeholder="Enter Multiple Points in Number"
                                    name="points"
                                  />
                                  {errors.name && touched.name ? (
                                    <ErrorMessage
                                      name="points"
                                      component={ErrorMessages}
                                    />
                                  ) : null}
                                </div>
                              </div>
                              <div className="col-lg-3 col-md-4 col-sm-6 col-12 ps-sm-2 px-0 form-check">
                                <div className="fw-bold fs-4 text-black mb-3">
                                  Month
                                </div>
                                <div className="mb-3">
                                  <Field
                                    type="number"
                                    className="form-control "
                                    id="entermonth"
                                    placeholder="Enter Months in Number"
                                    name="month"
                                  />
                                  {errors.name && touched.name ? (
                                    <ErrorMessage
                                      name="month"
                                      component={ErrorMessages}
                                    />
                                  ) : null}
                                </div>
                              </div>
                              <div className="col-lg-3 col-md-4 col-sm-6 col-12 ps-sm-2 px-0 form-check">
                                <div className="fw-bold fs-4 text-black mb-3">
                                  Free Events
                                </div>
                                <div className="mb-3">
                                  <Field
                                    type="number"
                                    className="form-control "
                                    id="enterclories"
                                    placeholder="Enter Events in Number"
                                    name="events"
                                  />
                                  {errors.name && touched.name ? (
                                    <ErrorMessage
                                      name="events"
                                      component={ErrorMessages}
                                    />
                                  ) : null}
                                </div>
                              </div>
                              <div className="12 px-0">
                                <button
                                  type="submit"
                                  className="btn  btn-dark fw-bold shadow-none"
                                >
                                  ADD
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Form>
                );
              }}
            </Formik>
          </div>
        </div>
      </div>
    </>
  );
};

export default AddNewPlan;
