import React from "react";
import { useState } from "react";
import { NavLink, Outlet } from "react-router-dom";
import DataTable from "react-data-table-component";
import { getData, postData } from "../../FetchService";
import { useEffect } from "react";
import { ToastContainer, toast } from "react-toastify";
import { ColorRing } from "react-loader-spinner";

function ManageSubscriptionPlans() {
  const [data, setData] = useState([]);
  const [loader, setLoader] = useState(false);
  const apidata = async () => {
    setLoader(true);
    const res = await getData("subscription-plans");
    setData(res.data);
    setLoader(false);
  };
  useEffect(() => {
    apidata();
  }, []);

  const editHandler = async (id) => {
    const res = await postData(`edit-subscription-plan/${id}`);
    if (res.status) {
      apidata();
      toast.success(res.message, {
        position: toast.POSITION.TOP_RIGHT,
      });
    } else {
      toast.error(res.message, {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  };

  const columns = [
    {
      name: "SR.NO",

      cell: (row, i) => i + 1,
      sortable: true,
      center: true,
    },
    {
      name: "SUBSCRIPTION PLAN NAME",
      selector: (row) => row.name,
      sortable: true,
      // center: true,
    },
    {
      name: "PLAN PRICE (₹)",
      selector: (row) => row.price,
      sortable: true,
      center: true,
    },
    {
      name: "PLAN DURATION",
      cell: (row) => (
        <div className="d-flex align-items-center justify-content-center mt-3">
          <p>{row.monthDuration} Months</p>
        </div>
      ),
      // center: true,
      sortable: true,
    },
    {
      name: "NUMBER OF FREE EVENTS",
      selector: (row) => row.eventsFree,

      center: true,
    },
    {
      name: "DESCRIPTION",
      selector: (row) => row.description.substring(0, 100),
      sortable: true,
      // center: true,
    },

    {
      name: "Actions",
      cell: (row) => (
        <div className="form-check form-switch">
          <input
            className="form-check-input"
            type="checkbox"
            id="stocksCheckbox1"
            checked={row.isEnabled}
            onChange={() => editHandler(row._id)}
          />
          <label className="form-check-label" htmlFor="stocksCheckbox1"></label>
        </div>
      ),
      sortable: true,
      center: true,
    },
  ];

  return (
    <>
      <ToastContainer autoClose={1000} />
      <div className="main mt-8">
        <div className="content container-fluid subscriptionOuter">
          <div className="pageheader">
            <div className="row align-items-center mb-3">
              <div className="col-sm mb-2 mb-sm-0">
                <h1 className="page-header-title m-0">
                  Manage Subscription Plans
                </h1>
              </div>
              <div className="col-auto">
                <NavLink to="addnewplan">
                  <button className="btn btn-dark pageBtn" type="button">
                    Add New Plan
                  </button>
                </NavLink>
              </div>
            </div>
          </div>
          {loader ? (
            <div style={{ marginLeft: "400px" }}>
              <ColorRing
                visible={true}
                height="80"
                width="80"
                ariaLabel="blocks-loading"
                wrapperStyle={{ marginTop: "100px" }}
                wrapperclassName="blocks-wrapper"
                colors={["#b8c480", "#B2A3B5", "#F4442E", "#51E5FF", "#429EA6"]}
              />
            </div>
          ) : (
            <div className="card">
              <div className="table-responsive datatable-custom rounded-top  subscriptionInner">
                <DataTable
                  columns={columns}
                  data={data}
                  pagination
                  fixedHeaderScrollHeight="408px"
                  highlightOnHover
                />
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
}

export default ManageSubscriptionPlans;
