import React, { useEffect } from "react";
import SideBar from "../../CommonPages/SideBar";
import Header from "../../CommonPages/Header";
import { NavLink, Link } from "react-router-dom";
import DataTable from "react-data-table-component";
import { getData, postData } from "../../FetchService";
import { useState } from "react";
import { ColorRing } from "react-loader-spinner";
import moment from "react-moment";
function Query() {
  const [query, setQuery] = useState([]);
  const [filter, setFilter] = useState([]);
  const [status, setStatus] = useState();
  const [isOpen, setIsOpen] = useState(false);
  const [updateComp, setupdateComp] = useState("");
  const [completedId, setcompletedId] = useState("");
  const [completedStatus, setcompletedStatus] = useState("");

  const [search, setSearch] = useState("");
  const [loader, setLoader] = useState(false);
  const [searchInput, setSaerchInput] = useState("");
  const viewquery = async (value) => {
    const res = await getData("get-raise-queries");
    setQuery(res.data);
    if (value == "all") {
      setStatus(1);
      setQuery(res.data);
    } else {
      setStatus(2);
      setFilter(res.data.filter((item) => item.status == value));
    }
  };

  const updatestatus = async (value, e) => {
    let body;
    if (e == 2) {
      setIsOpen(true);
      setcompletedId(value);
      setcompletedStatus(e);
    } else {
      body = {
        id: value,
        status: e,
      };
      const res = await postData("update-raise-query", body);
      if (res.status == true) {
        setTimeout(() => {
          setLoader(false);
          viewquery("all");
        }, 1000);
      }
    }
  };
  const handleComplted = async (value, e) => {
    let body = {
      id: value,
      status: e,
      response: updateComp,
    };
    const res = await postData("update-raise-query", body);
    if (res.status == true) {
      setTimeout(() => {
        setLoader(false);
        viewquery("all");
        setupdateComp("");
      }, 1000);
    }
  };

  useEffect(() => {
    viewquery("all");
  }, []);
  const handleSearch = (e) => {
    e.preventDefault();
    setSaerchInput(e.target.value);
    if (e.target.value) {
      let fill = query.filter((el) =>
        el.title.toLowerCase().includes(e.target.value)
      );
      setStatus(3);
      setSearch(fill);
    }
  };

  const columns = [
    {
      name: "SR.NO",
      selector: (row, i) => i + 1,
      sortable: true,
      center: true,
      width: "200px",
    },
    {
      name: "Query Id",
      selector: (row) => row._id,
      center: true,
      sortable: true,
      width: "200px",
    },
    {
      name: "Title",
      selector: (row) => row.title,
      center: true,
      sortable: true,
      width: "250px",
    },
    {
      name: "Description",
      selector: (row) => row.text,
      center: true,
      sortable: true,
      width: "250px",
    },
    {
      name: "Date ",
      selector: (row) => row.createdAt.split("T")[0],
      center: true,
      sortable: true,
      width: "250px",
    },
    {
      name: "Time ",
      selector: (row) => row.createdAt.split("T")[1].split(".")[0],
      center: true,
      sortable: true,
      width: "250px",
    },
    {
      name: "Status",
      cell: (row) => {
        if (row.status == 0) {
          return "Pending";
        } else if (row.status == 1) {
          return "Progress";
        } else if (row.status == 2) {
          return "Completed";
        }
      },
      center: true,
      width: "250px",
    },

    {
      name: "Actions",
      cell: (row) => (
        <div className="d-flex justify-content-center">
          <div className="tom-select-custom">
            <select
              id="datatableEntries"
              className="js-select form-select w-auto selectForm"
              autoComplete="off"
              onChange={(e) => updatestatus(row._id, e.target.value)}
            >
              <option value="QueryStatus" defaultValue>
                Update Query Status
              </option>
              <option value="0">Pending</option>
              <option value="1">In Progress</option>
              <option value="2">Completed</option>
            </select>
          </div>
          <Link
            className="btn btn-white btn-sm border-0"
            to={{
              pathname: "viewquery",
            }}
            state={{ details: row }}
          >
            <i className="bi bi-eye-fill me-1"></i>
          </Link>
        </div>
      ),
      center: true,
      width: "250px",
    },
  ];
  return (
    <>
      <div className="main mt-8">
        <div className="content container-fluid manageRaisedQueries">
          <div className="pageheader">
            <div className="row g-3 align-items-center mb-3">
              <div className="col-12">
                <h1 className="page-header-title m-0 pageTitle">
                  Manage Raised Queries
                </h1>
              </div>
              <div className="col-12">
                <form action="">
                  <div className="row g-2 mb-3">
                    <div className="col-8 col-sm-5 col-md-4">
                      <div className="input-group input-group-merge input-group-flush border rounded">
                        <div className="input-group-prepend input-group-text ms-2">
                          <i className="bi-search"></i>
                        </div>
                        <input
                          id="datatableSearch"
                          type="search"
                          className="form-control pe-2"
                          value={searchInput}
                          onKeyPress={(event) => {
                            if (event.charCode == 13) {
                              handleSearch(event);
                            }
                          }}
                          onChange={(e) => handleSearch(e)}
                          placeholder="Search by title"
                          aria-label="Search"
                        />
                      </div>
                    </div>
                  </div>
                </form>
              </div>
              <div className="col-12">
                <div className="row g-3">
                  <div className="col-12 tableTabs">
                    <ul
                      className="nav nav-segment"
                      id="leaderboardTab"
                      role="tablist"
                    >
                      <li className="nav-item ms-2">
                        <NavLink
                          className={`nav-link `}
                          id="all-time-tab"
                          data-bs-toggle="tab"
                          role="tab"
                          onClick={() => viewquery("all")}
                        >
                          All
                        </NavLink>
                      </li>
                      <li className="nav-item ms-2">
                        <NavLink
                          className="nav-link bg-light"
                          id="weekly-tab"
                          data-bs-toggle="tab"
                          role="tab"
                          onClick={() => viewquery(0)}
                        >
                          Pending
                        </NavLink>
                      </li>
                      <li className="nav-item ms-2">
                        <NavLink
                          className="nav-link bg-light"
                          id="monthly-tab"
                          data-bs-toggle="tab"
                          role="tab"
                          onClick={() => viewquery(1)}
                        >
                          In Progress
                        </NavLink>
                      </li>
                      <li className="nav-item ms-2">
                        <NavLink
                          className="nav-link"
                          id="monthly-tab2"
                          data-bs-toggle="tab"
                          role="tab"
                          onClick={() => viewquery(2)}
                        >
                          Completed
                        </NavLink>
                      </li>
                    </ul>
                  </div>
                  <div className="col-12">
                    <div className="tab-content" id="leaderboardTabContent">
                      <div
                        className="tab-pane fade show active"
                        id="all-time"
                        role="tabpanel"
                        aria-labelledby="all-time-tab"
                      >
                        <div className="card overflow-hidden">
                          {loader ? (
                            <div style={{ marginLeft: "400px" }}>
                              <ColorRing
                                visible={true}
                                height="80"
                                width="80"
                                ariaLabel="blocks-loading"
                                wrapperStyle={{ marginTop: "100px" }}
                                wrapperClass="blocks-wrapper"
                                colors={[
                                  "#b8c480",
                                  "#B2A3B5",
                                  "#F4442E",
                                  "#51E5FF",
                                  "#429EA6",
                                ]}
                              />
                            </div>
                          ) : (
                            <div className="table-responsive datatable-custom">
                              <DataTable
                                columns={columns}
                                pagination
                                data={
                                  status == 1
                                    ? query
                                    : status == 2
                                    ? filter
                                    : searchInput && status == 3
                                    ? search
                                    : query
                                }
                              />
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* modal */}
      <div
        className={`modal fade  ${isOpen == true ? "active show" : ""}`}
        id="multypleEventDelete"
        tabIndex={isOpen == true ? "-1" : ""}
        aria-labelledby="exampleModalLabel"
        aria-hidden={isOpen == true ? true : false}
        style={{ display: isOpen == true ? "block" : "none" }}
      >
        <div className="modal-dialog modal-dialog-centered " role="document">
          <div className="modal-content bg-secondary">
            <div className="modal-header">
              <input
                type="text"
                name="updateComp"
                value={updateComp}
                className="form-control"
                placeholder="response"
                aria-label="Username"
                onChange={(e) => setupdateComp(e.target.value)}
              />
            </div>
            <div className="modal-footer d-flex ">
              <button
                type="button"
                className="btn btn-danger"
                data-bs-dismiss="modal"
                aria-label="Close"
                onClick={() => {
                  setupdateComp(updateComp);
                  setIsOpen(false);
                  handleComplted(completedId, completedStatus);
                }}
              >
                Response
              </button>
              <button
                type="button"
                className="btn btn-dark"
                data-bs-dismiss="modal"
                aria-label="Close"
                onClick={() => {
                  setIsOpen(false);
                }}
              >
                Close
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Query;
