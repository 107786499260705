import React, { useEffect, useState, useCallback } from "react";
import Header from "../../CommonPages/Header";
import SideBar from "../../CommonPages/SideBar";
import { getData, postData, putData } from "../../FetchService";
import DataTable from "react-data-table-component";
import { NavLink, useNavigate } from "react-router-dom";
import { ColorRing } from "react-loader-spinner";

const ManageUsers = () => {
  const navigate = useNavigate();
  const [Users, setUsers] = useState([]);
  const [filterUsers, setFilterUsers] = useState([]);
  const [loader, setLoader] = useState(true);
  const [search, setsearch] = useState("");
  const [blockModal, setBlockModal] = useState(false);
  const [unblockModal, setUnblockModal] = useState(false);
  const [userData, setUserData] = useState({});
  const handleUsers = async () => {
    setLoader(true);
    const res = await getData("users");
    setUsers(res.data);
    setFilterUsers(res.data);
    setLoader(false);
  };
  useEffect(() => {
    handleUsers();
  }, []);

  const searchFilter = async (e) => {
    e.preventDefault();
    setsearch(e.target.value);
    let key = e.target.value;
    let res = await getData(`search?search=${key}&offset=${0}&limit=${10}`);

    setFilterUsers(res.data);
  };
  const ExpandedComponent = ({ data }) => {
    return (
      <div className="d-flex justify-content-start align-items p-3 w-50 m-3 border border-3">
        <div className="w-25">
          <p>
            <strong>User Name-</strong>
          </p>
          <p>
            <strong>Email-</strong>
          </p>
          <p>
            <strong>Mobile-</strong>
          </p>
          <p>
            <strong>Gender-</strong>
          </p>
          <p>
            <strong>Status-</strong>
          </p>
        </div>
        <div className="w-50">
          <p>
            {data.firstName && data.lastName ? (
              `${data.firstName} ${data.lastName}`
            ) : (
              <span className="text-danger">Not Given</span>
            )}
          </p>
          <p>
            {data.email ? (
              data.email
            ) : (
              <span className="text-danger">Not Given</span>
            )}
          </p>
          <p>
            {data.mobile ? (
              data.mobile
            ) : (
              <span className="text-danger">Not Given</span>
            )}
          </p>
          <p>
            {data.gender ? (
              data.gender
            ) : (
              <span className="text-danger">Not Given</span>
            )}
          </p>
          <p>
            {data.isBlocked ? (
              <span className="text-danger">Blocked</span>
            ) : (
              <span className="text-primary">Active</span>
            )}
          </p>
        </div>
      </div>
    );
  };

  const columns = [
    {
      name: "SR.NO",
      selector: (row, i) => i + 1,
      keyField: (index) => {
        console.log(index);
      },
      center: true,
    },
    {
      name: "Username",
      selector: (row) =>
        row.firstName && row.lastName ? (
          `${row.firstName} ${row.lastName}`
        ) : (
          <span className="text-danger">Not Given</span>
        ),
      sortable: true,
      style: { width: "200px" },
    },
    {
      name: "Logged in using",
      selector: (row) =>
        !row.socialId || !!row.socialId ? (
          <span className="text-danger">Not Given</span>
        ) : (
          row?.socialId?.substring(0, 15)
        ),
      sortable: true,
    },
    {
      name: "Mobile Number",
      selector: (row) =>
        row.mobile ? (
          row.mobile
        ) : (
          <span className="text-danger">Not Given</span>
        ),
      sortable: true,
    },
    {
      name: "Email Address",
      selector: (row) =>
        row.email ? row.email : <span className="text-danger">Not Given</span>,
      sortable: true,
      style: { width: "350px" },
    },
    {
      name: "Gender",
      selector: (row) =>
        row.gender ? (
          row.gender
        ) : (
          <span className="text-danger">Not Given</span>
        ),
      sortable: true,
      center: true,
    },
    {
      name: "Actions",
      cell: (row) => (
        <div className="d-flex justify-content-center">
          <div
            className="btn btn-white btn-sm border-0 me-2"
            onClick={() =>
              navigate("viewuserdeials", { state: { item: row._id } })
            }
          >
            <i className="bi bi-eye-fill me-1"></i>
          </div>
          {row.isBlocked ? (
            <div
              title="Unblock"
              className="btn btn-white btn-sm border-0"
              onClick={() => {
                setUnblockModal(true);
                setUserData(row);
              }}
            >
              <i class="bi bi-person-check-fill fs-4 fw-bold text-primary"></i>
            </div>
          ) : (
            <div
              title="Block"
              className="btn btn-white btn-sm border-0 text-danger"
              onClick={() => {
                setBlockModal(true);
                setUserData(row);
              }}
            >
              <i class="bi bi-person-fill-slash fs-4 fw-bold"></i>
            </div>
          )}
        </div>
      ),
      center: true,
    },
  ];
  const handleBlockUser = async (iid) => {
    const body = {
      id: iid,
    };
    const res = await putData(`block-user`, body);
    if (res.status) {
      setBlockModal(false);
      setUserData({});
      handleUsers();
    }
  };

  const handleUnblockUser = async (iid) => {
    const body = {
      id: iid,
    };
    const res = await putData(`unblock-user`, body);
    if (res.status) {
      setUnblockModal(false);
      setUserData({});
      handleUsers();
    }
  };
  return (
    <div className="main mt-8">
      <div className="content container-fluid manage_user">
        <div className="pageheader">
          <div className="row align-items-center mb-3">
            <div className="col-sm mb-2 mb-sm-0">
              <h1 className="page-header-title m-0">Manage Users </h1>
            </div>
          </div>
        </div>
        <div className="card">
          <div className="card-header border-bottom-0 card-header-content-md-between row">
            <div className="mb-2 mb-md-0 col-sm-auto">
              <form>
                <div className="input-group input-group-merge input-group-flush">
                  <div className="input-group-prepend input-group-text">
                    <i className="bi-search"></i>
                  </div>
                  <input
                    type="search"
                    className="form-control"
                    placeholder="Search users"
                    aria-label="Search users"
                    onChange={(e) => searchFilter(e)}
                    onKeyPress={(event) => {
                      if (event.charCode == 13) {
                        searchFilter(event);
                      }
                    }}
                  />
                </div>
              </form>
            </div>
          </div>
          {loader ? (
            <div style={{ marginLeft: "400px" }}>
              <ColorRing
                visible={true}
                height="80"
                width="80"
                ariaLabel="blocks-loading"
                wrapperStyle={{ marginTop: "100px" }}
                wrapperClass="blocks-wrapper"
                colors={["#b8c480", "#B2A3B5", "#F4442E", "#51E5FF", "#429EA6"]}
              />
            </div>
          ) : (
            <div className="table-responsive datatable-custom">
              <DataTable
                pagination
                columns={columns}
                data={search ? filterUsers : Users}
                highlightOnHover
                expandableRows
                expandableRowsComponent={ExpandedComponent}
              />
            </div>
          )}
          {/* <!-- Modal for delete --> */}

          <div
            className={`modal fade ${blockModal || unblockModal ? "show" : ""}`}
            style={{ display: blockModal || unblockModal ? "block" : "none" }}
            tabIndex="-1"
            role={blockModal || unblockModal ? "dialog" : ""}
            aria-hidden={blockModal || unblockModal ? "true" : "false"}
          >
            <div
              className="modal-dialog modal-dialog-centered "
              role="document"
            >
              <div className="modal-content border border-3">
                <div className="modal-header">
                  <h5 className="modal-title text-danger">Alert !</h5>
                </div>
                <div className="modal-body">
                  Do you want to {blockModal ? "block" : "unblock"}{" "}
                  {userData?.firstName && userData?.lastName ? (
                    <strong>
                      {` - ${userData?.firstName} ${userData?.lastName}`}
                    </strong>
                  ) : (
                    "this user"
                  )}
                </div>
                <div className="modal-footer">
                  <button
                    type="button"
                    className={`btn ${
                      blockModal ? "btn-danger" : " btn-primary"
                    }`}
                    onClick={() => {
                      blockModal
                        ? handleBlockUser(userData?._id)
                        : handleUnblockUser(userData?._id);
                    }}
                  >
                    {blockModal ? "Block" : "Unblock"}
                  </button>
                  <button
                    type="button"
                    className="btn btn-secondary"
                    onClick={() => {
                      setBlockModal(false);
                      setUnblockModal(false);
                      setUserData({});
                    }}
                  >
                    Close
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ManageUsers;
