import React, { useEffect, useState } from "react";
import Header from "../../CommonPages/Header";
import SideBar from "../../CommonPages/SideBar";
import { postData, getData } from "../../FetchService";
import { Formik, Form, Field, ErrorMessage } from "formik";
import { useLocation, useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import * as yup from "yup";
import ErrorMessages from "../../CommonPages/ErrorMessages";

const AllActivities = () => {
  // const [activity, setActivity] = useState("");
  const [button, setButton] = useState(false);
  const [eventTemplate, setEventTemplate] = useState([]);
  const [filterTemplate, setfilterTemplate] = useState([]);
  const [checkbox,setCheckbox]=useState([]);
  const [act, setAct] = useState("");
  const error = 'Daily is exceding';
  const navigate = useNavigate();
  const [initialValues, setInitialValues] = useState({
    name: "",
    description: "",
    dailyCalories: 0,
    limitCalories: 0,
    dailyDistance: 0,
    limitDistance: 0,
    dailyTime: 0,
    limitTime: 0,
    dailyJumps: 0,
    limitJumps: 0,
    dailySkips: 0,
    limitSkips: 0,
    dailySteps: 0,
    limitSteps: 0,
    activities: [],
  });


  const handleFormValues = (e) => {
    const { name, value, type, id } = e.target;
    if (type == "checkbox") {
      if (e.target.checked) {
        setInitialValues({ ...initialValues, [name]: value }) &&
          setInitialValues({ ...initialValues, [id]: value });
      } else {
        setInitialValues({ ...initialValues, [name]: "" }) &&
          setInitialValues({ ...initialValues, [id]: "" });
      }
    } else {
      setInitialValues({ ...initialValues, [name]: value });
    }
  };
  const location = useLocation();
  let edit = location.state.isEdit;

  useEffect(() => {
    if (edit == true) {
      setInitialValues({
        name: location.state.data.name,
        description: location.state.data.description,
        activities: filterTemplate,
        dailyCalories: location.state.data.calories?.daily,
        limitCalories: location.state.data.calories?.limit,
        dailyDistance: location.state.data.distance?.daily,
        limitDistance: location.state.data.distance?.limit,
        dailyTime: location.state.data.time?.daily,
        limitTime: location.state.data.time?.limit,
        dailyJumps: location.state.data.jumps?.daily,
        limitJumps: location.state.data.jumps?.limit,
        dailySkips: location.state.data.skips?.daily,
        limitSkips: location.state.data.skips?.limit,
        dailySteps: location.state.data.steps?.daily,
        limitSteps: location.state.data.steps?.limit,
      });
      let ar = [];
      location.state.data.activities.map((el) => {
        ar.push(`${el}`);
      });
      setfilterTemplate(ar);
      setButton(true);
    }
  }, [edit]);

  const handleEventTemplate = async () => {
    const res = await getData("activity-templates");
    setEventTemplate(res.data);
  };

  useEffect(() => {
    handleEventTemplate();
  }, []);

  const allFilterTemp = (ref) => {
    let data = ref.target.value;
    if (filterTemplate.join("").includes("12345")) {
      setfilterTemplate([]);
    } else {
      setfilterTemplate(data.split(","));
    }
  };

  const handleTemplateChange = (a) => {
    if (filterTemplate.includes(a)) {
      let fill = filterTemplate.filter((el) => el !== a);
      if (fill) {
        setfilterTemplate(fill);
      }
    } else {
      setfilterTemplate((prev) => [...prev, a]);
    }
  };
  const handleSubmit = async (value) => {
    validate();
    console.log(act, "act");
    const data = {
      name: value?.name,
      description: value?.description,
      activities: filterTemplate,
      calories: {
        daily: Number(value?.dailyCalories) ? Number(value?.dailyCalories) : 0,
        limit: Number(value?.limitCalories) ? Number(value?.limitCalories) : 0,
      },
      distance: {
        daily: Number(value?.dailyDistance) ? Number(value?.dailyDistance) : 0,
        limit: Number(value?.limitDistance) ? Number(value?.limitDistance) : 0,
      },
      time: {
        daily: Number(value?.dailyTime) ? Number(value?.dailyTime) : 0,
        limit: Number(value?.limitTime) ? Number(value?.limitTime) : 0,
      },
      jumps: {
        daily: Number(value?.dailyJumps) ? Number(value?.dailyJumps) : 0,
        limit: Number(value?.limitJumps) ? Number(value?.limitJumps) : 0,
      },
      skips: {
        daily: Number(value?.dailySkips) ? Number(value?.dailySkips) : 0,
        limit: Number(value?.limitSkips) ? Number(value?.limitSkips) : 0,
      },
      steps: {
        daily: Number(value?.dailySteps) ? Number(value?.dailySteps) : 0,
        limit: Number(value?.limitSkips) ? Number(value?.limitSkips) : 0,
      },
      rewardDesc: "nothig",
    };

    if (edit == true) {
      let id = location.state.data._id;
      const res = await postData(`edit-template/${id}`, data);
      if (res.status == true) {
        setTimeout(() => {
          navigate("/manageeventtemplate");
        }, 2000);
        toast.success(res.message + "!", {
          position: toast.POSITION.TOP_RIGHT,
        });
      } else {
        toast.error(res.message, {
          position: toast.POSITION.TOP_RIGHT,
        });
      }
    } else {
      const res = await postData("create-template", data);
      if (res.status == true) {
        setTimeout(() => {
          navigate("/manageeventtemplate");
        }, 2000);
        toast.success(`${res.message} !`, {
          position: toast.POSITION.TOP_RIGHT,
        });
      } else {
        toast.error(res.message, {
          position: toast.POSITION.TOP_RIGHT,
        });
      }
    }
  };
  const validate = () => {
    if (!filterTemplate?.length) {
      setAct("Atleast 1 activity must be selected ! ");
    } else {
      setAct("");
    }
  };
  const myValidationSchema = () =>
    yup.object().shape({
      name: yup.string().required("Name is required !"),
      description: yup.string().required("Description is required !"),
      dailyCalories: yup.string().required("Field is required !"),
      limitCalories: yup.string().required("Field is required !"),
      dailyDistance: yup.string().required("Field is required !"),
      limitDistance: yup.string().required("Field is required !"),
      dailyTime: yup.string().required("Field is required !"),
      limitTime: yup.string().required("Field is required !"),
      dailySteps: yup.string().required("Field is required !"),
      limitSteps: yup.string().required("Field is required !"),
    });
  const allValidationSchema = () =>
    yup.object().shape({
      name: yup.string().required("Name is required !"),
      description: yup.string().required("Description is required !"),
      dailyCalories: yup.string().required("Field is required !"),
      limitCalories: yup.string().required("Field is required !"),
      dailyDistance: yup.string().required("Field is required !"),
      limitDistance: yup.string().required("Field is required !"),
      dailyTime: yup.string().required("Field is required !"),
      limitTime: yup.string().required("Field is required !"),
      dailyJumps: yup.string().required("Field is required !"),
      limitJumps: yup.string().required("Field is required !"),
      dailySkips: yup.string().required("Field is required !"),
      limitSkips: yup.string().required("Field is required !"),
      dailySteps: yup.string().required("Field is required !"),
      limitSteps: yup.string().required("Field is required !"),
    });
  const fiveValidationSchema = () =>
    yup.object().shape({
      name: yup.string().required("Name is required !"),
      description: yup.string().required("Description is required !"),
      dailyCalories: yup.string().required("Field is required !"),
      limitCalories: yup.string().required("Field is required !"),
      dailyDistance: yup.string().required("Field is required !"),
      limitDistance: yup.string().required("Field is required !"),
      dailyTime: yup.string().required("Field is required !"),
      limitTime: yup.string().required("Field is required !"),
      dailyJumps: yup.string().required("Field is required !"),
      limitJumps: yup.string().required("Field is required !"),
      dailySkips: yup.string().required("Field is required !"),
      limitSkips: yup.string().required("Field is required !"),
    });
  const threeValidationSchema = () =>
    yup.object().shape({
      name: yup.string().required("Name is required !"),
      description: yup.string().required("Description is required !"),
      dailyCalories: yup.string().required("Field is required !"),
      limitCalories: yup.string().required("Field is required !"),
      dailyDistance: yup.string().required("Field is required !"),
      limitDistance: yup.string().required("Field is required !"),
      dailyTime: yup.string().required("Field is required !"),
      limitTime: yup.string().required("Field is required !"),
      dailyJumps: yup.string().required("Field is required !"),
      limitJumps: yup.string().required("Field is required !"),
      dailySteps: yup.string().required("Field is required !"),
      limitSteps: yup.string().required("Field is required !"),
    });
  return (
    <>
      <ToastContainer autoClose={1000} />
      <div className="main mt-8">
        <div className="content container-fluid">
          <div className="pageheader">
            <div className="row align-items-center mb-3">
              <div className="col-sm mb-2 mb-sm-0">
                <h1 className="page-header-title m-0">
                  {button ? "Edit" : "Add"} Event Templates
                </h1>
              </div>
            </div>
          </div>
          <div className="card card-lg mb-3 mb-lg-5">
            <div className="card-header px-sm-4 px-3">
              <h4 className="card-header-title">Details</h4>
            </div>
            <div className="card-body p-3 p-sm-4">
              <Formik
                initialValues={initialValues}
                onSubmit={handleSubmit}
                enableReinitialize
                validationSchema={
                  filterTemplate.sort().join("").includes("12345")
                    ? allValidationSchema
                    : filterTemplate.sort().join("").includes("3")
                    ? threeValidationSchema
                    : filterTemplate.sort().join("").includes("5")
                    ? fiveValidationSchema
                    : myValidationSchema
                }
              >
                {({ values, errors, touched }) => {
                  console.log("values", values);
                  return (
                    <Form>
                      <div className=" col-sm-4 mb-3">
                        <label htmlFor="eventtitle" className="form-label">
                          Event Title
                        </label>
                        <Field
                          type="text"
                          className="form-control"
                          name="name"
                          id="eventtitle"
                          aria-describedby="emailHelp"
                          placeholder="Enter Event Title"
                        />
                        {errors.name && touched.name ? (
                          <ErrorMessage name="name" component={ErrorMessages} />
                        ) : null}
                      </div>
                      <div className="mb-3">
                        <label htmlFor="messageText" className="form-label">
                          Description
                        </label>
                        <Field
                          type="text"
                          className="form-control"
                          id="messageText"
                          placeholder=" Add Description"
                          name="description"
                        />
                        {errors.description && touched.description ? (
                          <ErrorMessage
                            name="description"
                            component={ErrorMessages}
                          />
                        ) : null}
                      </div>

                      <div className="row mx-0 ">
                        <div className="col-12 px-0 fs-5 fw-bold text-black">
                          Activity Type (Multiple Selection)
                        </div>
                        <div className="col-12 chech_Box_Custom px-0">
                          <div>
                            
                         <small className={filterTemplate.length ? 'd-none' : 'd-block text-danger'} >Please Select any of category*</small>
                            <ul
                              className="nav nav-tabs border-bottom-0 d-flex align-items-center scroll_card"
                              id="myTab"
                              role="tablist"
                            >
                              <li className="nav-item" role="presentation">
                                <button
                                  className="nav-link border-bottom-0 px-0 d-flex align-items-center active"
                                  id="home-tab"
                                  data-bs-toggle="tab"
                                  data-bs-target="#home"
                                  type="button"
                                  role="tab"
                                  aria-controls="home"
                                  aria-selected="true"
                                >
                                  <span>
                                    {" "}
                                    <div className="form-check">
                                      <input
                                        className="form-check-input rounded-1 p-2"
                                        type="checkbox"
                                        value={'1,2,3,4,5'}
                                        id="flexCheckDefault"
                                        name="activities"
                                        //checked={initialValues.activities.length !=[] ?true:false}
                                        onChange={(e) => allFilterTemp(e)}
                                        checked={filterTemplate
                                          .sort()
                                          .join("")
                                          .includes("12345")
                                        }
                                      />
                                      <label
                                        className="form-check-label"
                                        htmlFor="flexCheckDefault"
                                      >
                                        All
                                      </label>
                                    </div>
                                  </span>
                                </button>
                              </li>
                              {eventTemplate.map((el, i) => {
                                return (
                                  <div
                                    key={Math.random()}
                                    style={{ marginBottom: "0px" }}
                                  >
                                    <li
                                      className="nav-item"
                                      role="presentation"
                                    >
                                      <button
                                        className="nav-link border-bottom-0 px-3 d-flex align-items-center"
                                        id="profile-tab"
                                        data-bs-toggle="tab"
                                        data-bs-target="#profile"
                                        type="button"
                                        role="tab"
                                        aria-controls="profile"
                                        aria-selected="false"
                                        tabIndex="-1"
                                      >
                                        <span>
                                          {" "}
                                          <div className="form-check">
                                            <input
                                            type='checkbox'
                                            className="form-check-input rounded-1 p-2"
                                            value={el.ref}
                                            onChange={(e)=>handleTemplateChange(e.target.value)}
                                          
                                            checked={filterTemplate.includes(
                                              `${el.ref}`
                                            )}
                                            />
                                            <label
                                              className="form-check-label"
                                              htmlFor={`${el._id}flexCheckCheckedRunning`}
                                            >
                                              {el.activity}
                                            </label>
                                          </div>
                                        </span>
                                      </button>
                                    </li>
                                  </div>
                                );
                              })}
                            </ul>
                            {act && <ErrorMessages children={act} />}
                          </div>

                          <div className="tab-content mt-2" id="myTabContent">
                            <div
                              className="tab-pane fade active show"
                              id="home"
                              role="tabpanel"
                              aria-labelledby="home-tab"
                            >
                               <div className="row mx-0 ">
                        <div className="col-lg-2 col-md-3 col-sm-5 col-12 form-check px-0 pe-sm-2">
                          <div className="fw-bold fs-4 text-black mb-3 ">
                            Calories
                          </div>
                          <div className="mb-3">
                            <span> Daily: </span>
                            <input
                              style={{ width: "60%", display: "inline-block" }}
                              type="text"
                              name="dailyCalories"
                              className="form-control"
                              id="dailyCalories"
                              onClick={(e) => e.target.select()}
                              value={Number(initialValues.dailyCalories) <= Number(initialValues.limitCalories) ? initialValues.caloriesDaily : 0}
                              onChange={handleFormValues}
                            /><br/>
                            {Number(initialValues.dailyCalories) <= Number(initialValues.limitCalories) ? null :<small id='dailyCalories' className="text-danger">{error}</small> }
                          </div>

                          <div className="mb-3">
                            <span> Limit: </span>
                            <input
                              style={{ width: "60%", display: "inline-block" }}
                              type="text"
                              name="limitCalories"
                              className="form-control"
                              id="limitCalories"
                              onClick={(e) => e.target.select()}
                              value={initialValues.limitCalories}
                              onChange={handleFormValues}
                            />
                          </div>
                        </div>
                        <div className="col-lg-2 col-md-3 col-sm-5 col-12 ps-sm-2 px-0 form-check  d-flex row justify-space-between">
                          <div className="fw-bold fs-4 text-black mb-3">
                            Time
                          </div>
                          <div className="mb-3">
                            <span> Daily: </span>
                            <input
                              style={{ width: "60%", display: "inline-block" }}
                              type="text"
                              name="dailyTime"
                              className="form-control"
                              id="dailyTime"
                              onClick={(e) => e.target.select()}
                              // value={formvalues.timeDaily}
                              value={Number(initialValues.dailyTime) <= Number(initialValues.limitTime) ? initialValues.dailyTime : 0}
                              onChange={handleFormValues}
                            /><br/>
                            {Number(initialValues.dailyTime) <= Number(initialValues.limitTime) ? null :<small id='dailyTime' className="text-danger">{error}</small> }
                          </div>

                          <div className="mb-3">
                            <span> Limit: </span>
                            <input
                              style={{ width: "60%", display: "inline-block" }}
                              type="text"
                              name="limitTime"
                              className="form-control"
                              id="limitTime"
                              onClick={(e) => e.target.select()}
                              value={initialValues.limitTime}
                              onChange={handleFormValues}
                            />
                          </div>
                        </div>

                       
                        <div className="col-lg-2 col-md-3 col-sm-5 col-12 form-check px-0 pe-sm-2">
                          <div className="fw-bold fs-4 text-black mb-3 ">
                            Distance
                          </div>

                          <div className="mb-3">
                            <span> Daily: </span>
                            <input
                              style={{ width: "60%", display: "inline-block" }}
                              type="text"
                              name="dailyDistance"
                              className="form-control"
                              id="dailyDistance"
                              onClick={(e) => e.target.select()}
                              value={Number(initialValues.dailyDistance) <= Number(initialValues.limitDistance) ? initialValues.distanceDaily : 0}
                              onChange={handleFormValues}
                            /><br/>
                            {Number(initialValues.dailyDistance) <= Number(initialValues.limitDistance) ? null : <small id='dailyDistance' className="text-danger">{error}</small> }
                          </div>

                          <div className="mb-3">
                            <span> Limit: </span>
                            <input
                              style={{ width: "60%", display: "inline-block" }}
                              type="text"
                              name="limitDistance"
                              className="form-control"
                              id="limitDistance"
                              onClick={(e) => e.target.select()}
                              value={initialValues.limitDistance}
                              onChange={handleFormValues}
                            />
                          </div>
                        </div>
                      


                        {filterTemplate
                          .sort()
                          .join("")
                          .includes("3")
                          ?
                          <div className="col-lg-2 col-md-3 col-sm-5 col-12 form-check px-0 pe-sm-2">
                            <div className="fw-bold fs-4 text-black mb-3 ">
                              Jumps
                            </div>

                            <div className="mb-3">
                              <span> Daily: </span>
                              <input
                                style={{ width: "60%", display: "inline-block" }}
                                type="text"
                                name="dailyJumps"
                                className="form-control"
                                id="dailyJumps"
                                onClick={(e) => e.target.select()}
                                value={Number(initialValues.dailyJumps) <= Number(initialValues.limitJumps) ? initialValues.dailyJumps : ''}
                                onChange={handleFormValues}
                              /><br/>
                              {Number(initialValues.dailyJumps) <= Number(initialValues.limitJumps) ? null :<small id='dailyJumps' className="text-danger">{error}</small> }
                            </div>

                            <div className="mb-3">
                              <span> Limit: </span>
                              <input
                                style={{ width: "60%", display: "inline-block" }}
                                type="text"
                                name="limitJumps"
                                className="form-control"
                                id="limitJumps"
                                onClick={(e) => e.target.select()}
                                value={initialValues.limitJumps}
                                onChange={handleFormValues}
                              />
                            </div>
                          </div> : null}


                        {filterTemplate
                          .sort()
                          .join("")
                          .includes("5")
                          ?
                          <div className="col-lg-2 col-md-3 col-sm-5 col-12 form-check px-0 pe-sm-2">
                            <div className="fw-bold fs-4 text-black mb-3 ">
                              Skips
                            </div>

                            <div className="mb-3">
                              <span> Daily: </span>
                              <input
                                style={{ width: "60%", display: "inline-block" }}
                                type="text"
                                name="dailySkips"
                                className="form-control"
                                id="dailySkips"
                                onClick={(e) => e.target.select()}
                                value={Number(initialValues.dailySkips) <= Number(initialValues.limitSkips) ? initialValues.dailySkips : 0}
                                onChange={handleFormValues}
                              /><br/>
                              {Number(initialValues.dailySkips) <= Number(initialValues.limitSkips) ? null :<small id='limitSkips' className="text-danger">{error}</small> }
                            </div>

                            <div className="mb-3">
                              <span> Limit: </span>
                              <input
                                style={{ width: "60%", display: "inline-block" }}
                                type="text"
                                name="limitSkips"
                                className="form-control"
                                id="limitSkips"
                                onClick={(e) => e.target.select()}
                                value={initialValues.limitSkips}
                                onChange={handleFormValues}
                              />
                            </div>
                          </div>
                          : null}


                        {filterTemplate
                          .sort()
                          .join("")
                          .includes("1") 
                          ||
                          filterTemplate
                          .sort()
                          .join("")
                          .includes("5")
                          ||
                          filterTemplate
                          .sort()
                          .join("")
                          .includes("4")
                          ?
                          <div className="col-lg-2 col-md-3 col-sm-5 col-12 form-check px-0 pe-sm-2">
                            <div className="fw-bold fs-4 text-black mb-3 ">
                              Steps
                            </div>

                            <div className="mb-3">
                              <span> Daily: </span>
                              <input
                                style={{ width: "60%", display: "inline-block" }}
                                type="text"
                                name="dailySteps"
                                className="form-control"
                                id="dailySteps"
                                onClick={(e) => e.target.select()}
                                value={Number(initialValues.dailySteps) <= Number(initialValues.limitSteps) ? initialValues.dailySteps : 0}
                                onChange={handleFormValues}
                              /><br/>
                              {Number(initialValues.dailySteps) <= Number(initialValues.limitSteps) ? null :<small id='dailySteps' className="text-danger">{error}</small> }
                            </div>

                            <div className="mb-3">
                              <span> Limit: </span>
                              <input
                                style={{ width: "60%", display: "inline-block" }}
                                type="text"
                                name="limitSteps"
                                className="form-control"
                                id="limitSteps"
                                onClick={(e) => e.target.select()}
                                value={initialValues.limitSteps}
                                onChange={handleFormValues}
                              />
                            </div>
                          </div>
                          : null}
                      </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </Form>
                  );
                }}
              </Formik>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AllActivities;
